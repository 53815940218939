<template>
  <div>
  <v-dialog
      v-model="activo"
      width="750"
      scrollable
    >
      <v-card>
        <v-card-title class="">
          <div style="font-size: 20px" class="d-flex ">
            Markups
            <v-btn
              class="ml-2"
              color="success"
              title="Nuevo Markup"
              x-small fab
              @click="nuevoEditarMarkup(null)"
            >
              <v-icon small>fas fa-plus</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form @submit.prevent="guardarCambios()">
            <v-row class="pt-2">
              <v-col cols="12" sm="6" md="6" class="py-1" :class="pantallaChica ? 'px-1' : 'pl-1'">
                Categoria
                <v-text-field
                  v-model="categoria.nombre"
                  hide-details
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-1" :class="pantallaChica ? 'px-1' : 'pr-1'">
                Listas
                <v-autocomplete
                  v-model="lista_codigo"
                  item-text="lista_nombre"
                  item-value="lista_codigo"
                  :items="listas"
                  hide-details
                  outlined
                  dense
                  clearable
                  @change="buscar()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="py-1 px-1">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headers"
                  :items="markups"
                  :loading="load"
                  dense
                  :search="search"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTable
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Markup -->
                  <template v-slot:[`item.markup`]="{ item }">
                    {{ item.markup }}%
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.accion`]="{ item }">
                    <div class="d-flex justify-center align-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="warning" @click="nuevoEditarMarkup(item)" v-on="on">
                            <v-icon class="mr-2" small>fas fa-pen</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <BtnConfirmar
                            icon_button
                            small
                            icono="fas fa-trash"
                            color="error"
                            texto="¿Estás seguro de eliminar el markup para esta categoría?"
                            @action="eliminarMarkup(item)"
                          />
                          </div>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos de los Markups
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <ModalNvoEditarMarkup
          v-model="objMarkup.activo"
          :nuevo="objMarkup.nuevo"
          :p_lista="this.objMarkup.lista"
          :categoria="categoria"
          :datos="objMarkup"
          @actualizar="actualizarLista"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  import SearchDataTable from '../../util/SearchDataTable.vue';
  import { roundNumber } from '../../../util/utils';
  import ModalNvoEditarMarkup from './ModalNvoEditarMarkup.vue';
  import BtnConfirmar from '../../util/BtnConfirmar.vue';

  export default {
    name: 'ModalMarkups',
    props:{
      value: Boolean,
      categoria: { type: Object, require: true, }
    },
    computed:{
      activo:{
        get(){
          return this.value;
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    data(){
      return{
        roundNumber: roundNumber,
        lista_codigo: 0,
        load: false,
        listas: [],
        search: '',
        headers: [
          { text: 'Lista', value: 'lista_nombre' },
          { text: 'Markup', value: 'markup', align: 'end' },
          { text: 'Acciones', value: 'accion', align: 'center' },
        ],
        markups: [],
        objMarkup:{
          activo: false,
          nuevo: false,
          markup: null,
          lista_codigo: 0,
          lista: {},
          id_markup: 0,
        },
        pantallaChica: this.$vuetify.breakpoint.xs,
      }
    },
    created(){
    },
    methods:{
      async init(){
        await new Promise(resolve => setTimeout(resolve, 1))
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/initMarkups');
        this.$store.state.loading = false;
        
        if(res.resultado == 0){
          this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
        }
        this.listas = res.listas;
        this.lista_codigo = 0;
        this.buscar();
      },  
      async buscar(){
        this.lista_codigo = !this.lista_codigo ? 0 : this.lista_codigo;
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/buscarMarkups', { categoria_codigo: this.categoria.codigo, lista_codigo: this.lista_codigo });
        this.$store.state.loading = false;
        
        if(res.resultado == 0){
          this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
        }
        this.markups = res.markups;
        this.markups.forEach(e => e.markup = roundNumber(e.markup,2))
      },
      nuevoEditarMarkup(item){
        if(item){
          this.objMarkup.nuevo        = false;
          this.objMarkup.id_markup    = item.id_markup,
          this.objMarkup.markup       = item.markup;
          this.objMarkup.lista_codigo = item.lista_codigo;
          this.objMarkup.lista        = (this.listas.filter(e => e.lista_codigo == item.lista_codigo))[0]
        }else{
          this.objMarkup.nuevo        = true;
          this.objMarkup.id_markup    = 0;
          this.objMarkup.markup       = null;
          this.objMarkup.lista_codigo = 0;
        }
        this.objMarkup.activo = true;
      },
      actualizarLista(item){   
        if(this.objMarkup.nuevo){
        }    
        this.lista_codigo = item.lista_codigo
        this.buscar();
      },
      async eliminarMarkup(item){
        
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/eliminarMarkup', { id_markup: item.id_markup });
        this.$store.state.loading = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
        }
        this.$store.dispatch('show_snackbar', { text: 'El markup fue se eliminó con éxito.', color: 'success' })

        let pos = this.markups.map(e => e.id_markup).indexOf(item.id_markup);
        if(pos != -1){
          this.markups.splice(pos, 1);
        }
      },
    },
    components:{
      SearchDataTable, ModalNvoEditarMarkup, BtnConfirmar
    },
    watch:{
      activo: function(val){
        if(val){
          this.init();
        }else{
          this.listas = [];
        }
      },
      '$vuetify.breakpoint.xs'(val){
        this.pantallaChica = val;
      },
    }
  }
</script>
  
<style>

</style>